/**
 * 
 * @param {int} id document’s id 
 * @returns {string} GraphQL Query
 */
 export function getDocumentContentQuery(id) {
    return `DocumentContentQuery(id: ${id})`;
}

/**
 * 
 * @param {int} idDossier 
 * @returns {string} GraphQL Query
 */
export function getDocumentListQuery(idDossier) {
    return `DocumentByDossierQuery(idDossier: ${idDossier}){
        id
        date
        dateOfAsk
        label
        category {
            id
            label
            visible
        }
        type
        completed
        signingAvailable
        file {
            date
            mimeType
            size
        }
        links {
            type
            id
        }
    }`;
}

/**
 * 
 * @param {int} idDossier
 * @param {string} status
 * @returns {string} GraphQL Query
 */
export function getDocumentSigningByDossierQuery(idDossier, status) {
    return `DocumentSigningByDossierQuery(idDossier: ${idDossier}, status: "${status}"){
        id
        status
        dateSend
        dateSignatureEmprunteur
        dateSignatureCoemprunteur
        dateRefusal
        dateExpiration
        refusalReason
        label
        transactionId
    }`;
}

/**
 * 
 * @param {string} transactionId
 * @param {string} email
 * @returns {string} GraphQL Query
 */
export function getDocumentSigningLinkQuery(transactionId, email) {
    return `DocumentSigningLinkQuery(transactionId: "${transactionId}", email: "${email}"){
        link
    }`;
}

/**
 * 
 * @param {int} idDossier
 * @returns {string} GraphQL Query
 */
export function getLegalDocumentListByDossierQuery(idDossier) {
    return `LegalDocumentListByDossierQuery(id: ${idDossier}){
        id
        dossierId
        variable
        dateConsultation
        dateProvision
        dateValidation
        dateRefus
    }`;
}

/**
 *
 * @param {int} id 
 * @param {int|null} dateConsultation 
 * @param {int|null} dateProvision 
 * @param {int|null} dateValidation 
 * @param {int|null} dateRefus
 * @returns {string}
 */
export function patchLegalDocumentMutation(id, dateConsultation, dateProvision, dateValidation, dateRefus) {
    return `LegalDocumentMutation(id: ${id}, dateConsultation: ${dateConsultation}, dateProvision: ${dateProvision}, dateValidation: ${dateValidation}, dateRefus: ${dateRefus}){
        id
        dossierId
        variable
        dateConsultation
        dateProvision
        dateValidation
        dateRefus
    }`;
}
