<template>
  <router-view></router-view>
</template>

<script>
export default {}
</script>

<style lang="scss">

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #004263;
  margin-top: 60px;
}
</style>
